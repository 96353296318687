var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
var flyoutNav = true;
var hoverSensitiveNav = true;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;
// var carouselBackgroundVideoYouTubeID = 'LXb3EKWsInQ'; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;


// Vimeo background video (Fresco only supports YouTube by default)
if (($('body').hasClass('homepage') && !$('body').hasClass('subsite'))) {
  $('.carouselSlide:first-child .carouselSlideTitle').append(
    '<div class="yt-container"><iframe src="https://player.vimeo.com/video/919624506?background=1&muted=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>'
  );
}

// Resize .yt-container
function resizeYtContainer(ratioWidth = 16, ratioHeight = 9) {
  var ratio = ratioWidth / ratioHeight;
  var containerHeight = parseInt($('.yt-container').css('height'));
  var containerWidth = containerHeight * ratio;
  $('.yt-container').css({
    'width': containerWidth
  });
}


resizeYtContainer();
$(window).resize(function () {
  resizeYtContainer();
});

// Reposition nav into header
// $('#menuMain').clone().addClass('menuMainDesktop').insertBefore('.header-search');


// Carousel quick giving
if (($('body').hasClass('homepage') && !$('body').hasClass('subsite'))) {
  $('.carouselSlide').each(function () {

    var headerHeight = $('.pageHeader').height();
    // $('.carouselSlideTitle').css('height', 'calc(100vh -' + headerHeight + 'px)');
    $('.carouselSlideTitle').css( { height: 'calc(100vh - ' + headerHeight + 'px)' } );

  //   if ($(this).find('.totaliserPanel').length == 1) {
  //     $(this).find('.totaliserPanel, .totaliserGivingPanel, #totaliserActions').wrapAll('<div class="carouselQuickGiving"></div>');
  //     $(this).find('.carouselSlideDetail > *:not(.carouselQuickGiving)').wrapAll('<div class="carouselText"></div>');
  //     $(this).find('.donationAmount').wrapAll('<div class="donationAmounts"></div>');
  //   }
  })
  // $('.carouselQuickGiving .selectDonationAmount > span').text('Choose an amount to give');
  // $('input.regularPayment').attr('id', 'regularPayment'); // The platform should really do this but eh. Required to make clicking the label activate the checkbox.
};


// Hire page customisations
if ($('body').hasClass('hire')) {
  $('.imagecolumn').each(function () {
    var imageUrl = $(this).find('img').attr('src');
    $(this).css("background-image", "url('" + imageUrl + "')");
    $(this).find('img').css('visibility', 'hidden');
  })
}


// Resize images for Timeline pages
$('.listedPost.PostCategory_timeline img.banner.listingBanner').each(function () {
  var updatedSrc = $(this)
    .attr('src')
    .replace(/w=([0-9]*)&h=([0-9]*)/, 'w=600&h=600') // Non smart-cropped
    .replace(/width=([0-9]*)&height=([0-9]*)/, 'width=600&height=600'); // Smart cropped
  $(this).attr('src', updatedSrc);
});


// Footer logos
$('.footerBox[class*="ogo"]').insertAfter('.pageFooterWrapper');


// Feeds Title
$('.feedsTitle, h1.title').each(function (){
  $(this).wrapInner('<span class="titleInner" />');
});


// Homepage quick giving panel
$(document).ready(function () {
  $('.homefeaturecategory-homeboxquickgiving').insertAfter('.carousel');
});


// Slick slider
$(".homeFeed.homeFeedsnippets").find(".feedList").slick({
  slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  pauseOnHover: true,
  pauseOnDotsHover: true,
  autoplay: true,
  autoplaySpeed: 6000
});


// Subsite - Spring into Action - Competition page
if ($('body.pagesPostBody').hasClass('PostCategory_gallery-layout')) {
  
  $('.post .contentBlock .postContent table td').each(function () {
    $(this).find('img').clone().removeClass('mediaImage').addClass('enlarged').appendTo($(this));
    $(this).click(function () {
      $(this).toggleClass('active');
    });
  });

  // Set image height
  $(window).on("load resize scroll", function () {
    $('.post .contentBlock .postContent table img.mediaImage:not(.enlarged)').each(function () {
      var imageWidth = $(this).innerWidth(); 
      $(this).attr('style', 'height:' + imageWidth + 'px' + '!important;')
    });
  });
      
}